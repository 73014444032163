import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {ME} from '../../graphql/queries';
import {navigate} from 'gatsby';

import ForgotPassword from '../../components/forgot-password';
import SimpleLayout from '../../components/layout_simple';
import Login from '../../components/login';
import {arrowLeft} from '../../images/svgIcons';
import './styles.scss';

const LoginPage = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const onBackBtnClick = () => {
    setShowForgotPassword(false);
  };
  const {data: userData} = useQuery(ME);

  useEffect(() => {
    if (userData && userData.User && userData.User.get && userData.User.get.id) {
      navigate('/');
    }
  }, [userData]);

  return (
    <SimpleLayout isClickable>
      {!showForgotPassword ? (
        <Login setShowForgotPassword={setShowForgotPassword} />
      ) : (
        <div className="forgot-password-section">
          <span className="go-back" onClick={onBackBtnClick}>
            {arrowLeft}
          </span>
          <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
        </div>
      )}
    </SimpleLayout>
  );
};

export default LoginPage;
